<template>
  <v-responsive>
    <SubMenuMypageMobile v-if="$vuetify.breakpoint.smAndDown"></SubMenuMypageMobile>
    <v-container :fluid="$vuetify.breakpoint.lgAndDown" style="max-width:1440px;"> 
      <v-row>
        <v-col cols="12" sm="3" flat tile v-if="$vuetify.breakpoint.mdAndUp">
          <v-container class="pa-0">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0 pl-6" flat tile>
                <h2
                  class="
                    bx_pagetitle
                    float-left
                    mt-5
                    mb-3
                    blue--text
                    text--darken-4
                  "
                >
                  {{ breadcrumbs[1].text }}
                </h2>
              </v-card>
            </v-card>
          </v-container>
          <v-divider></v-divider>

          <SubMenuMypage></SubMenuMypage>
        </v-col>
        <v-col cols="12" sm="12" md="9" :class="$vuetify.breakpoint.smAndDown? 'mt-0 pt-0':''">
          <v-container class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0" flat tile>
                <h2 class="bx_pagetitle float-left mt-5 mb-3">
                  {{ breadcrumbs[2].text }}
                </h2>
              </v-card>
              <v-card class="pa-0" flat tile v-if="$vuetify.breakpoint.mdAndUp">
                <v-breadcrumbs :items="breadcrumbs" class="float-right mt-2">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-card>
            </v-card>
          <v-divider></v-divider>
          </v-container>


          
          <v-sheet min-height="58vh">
            <!--  -->







            <v-card flat class="mt-6">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                         readonly 
                        label="전화번호"
                        value="01012345678"
                        hide-details	

                        
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        label="닉네임"
                        persistent-hint
                        hide-details	
                        required
          :counter="10"
          append-outer-icon="mdi-check-circle-outline"
            @click:append-outer="payPop= !payPop"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                   
        <v-text-field
        class="input-right"
          label="보유 포인트"
          value="5000"
          readonly
                        hide-details	
          prefix=""
          append-outer-icon="mdi-plus-circle-outline"
            @click:append-outer="payPop= !payPop"
        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      
        <v-checkbox
          value="1"
          label="로또정보 수신 동의"
          small
          type="checkbox"
                        hide-details	
          required
        ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>



  <v-dialog v-model="payPop"
    max-width="500"
    @click:outside="onClickOutside"
  >
  
    <v-card>
      <v-card-title class="justify-center mb-4 pt-5" style="font-size: 1.6rem">
        <div><strong>포인트 충전</strong></div>
      </v-card-title>
      <v-card-subtitle class="text-center pb-2 font-weight-bold">
        <div>로또7의 프리미엄 서비스 이용에 사용할 수 있는 포인트를 충전합니다.</div>
      </v-card-subtitle>

            <PayWindow class="mt-5 ma-10"  @close-event="closeDialog"></PayWindow>

      <v-card-actions>
      </v-card-actions>
    </v-card>
    
  </v-dialog>



              <v-row class="my-5 mx-0" style="background: #fff">




                <v-col  xs="12"  sm="12" md="6" >      
                  <!--당첨자목록 S-->
                      <v-row class="py-1">

                      </v-row>
                      <v-img
                        alt="Vuetify Logo"
                        class="shrink mt-1"
                        contain
                        src="@/assets/tmp_sub_banner1.png"
                        full-width
                      />
                  <!--당첨자목록 E -->
                </v-col>
                <v-col  xs="12"  sm="12" md="6">      
                  <!-- 조합도구 S -->  
                      <v-row class="py-1">

                      </v-row>
                      <v-img
                        alt="Vuetify Logo"
                        class="shrink mt-1"
                        contain
                        src="@/assets/tmp_sub_banner2.png"
                        full-width
                      />
                  <!--조합도구 E -->
                </v-col>
                <v-col cols="12" >

          <v-card class="d-flex justify-center" flat
            to="/join">
            <v-img
              src="@/assets/join_banner.png"
            ></v-img>
          </v-card>
                </v-col>
              </v-row>





          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-responsive>
</template>

<script>
import SubMenuMypage from "../components/SubMenuMypage.vue";
//import HelloWorld from "../components/HelloWorld";

export default {
  name: "Mypage",

  components: {
    SubMenuMypage,
    //HelloWorld,
  },
  data: () => ({
    payPop: false,

    breadcrumbs: [
      { text: "홈" },
      { text: "마이페이지" },
      { text: "내 정보 관리" },
      //{ text: "당첨 통계", icon: "mdi-menu-right" },
    ],
  }),


  methods: {
    onClickOutside() {
      if (!this.persistent) {
        this.$store.dispatch("closeAlertDialog");
      }
    },
    onClickButton() {
      this.onClickOutside();
    },
    closeDialog() {
      this.payPop= false
    },
  },


};
</script>
<style lang="scss" scoped>


</style>
